<template>
  <div class="row">
    <div class="card card-body">
        <div class="col-12 table-responsive">
            <table class="table table-sm table-bordered">
                <thead>
                    <th>
                        الاسم
                    </th>
                    <th>
                        الجوال
                    </th>
                    <th>
                        كلمة المرور
                    </th>
                    <th>
                        تاريخ التسجيل
                    </th>
                    <th>
                        اشتراك تجريبي
                    </th>
                    <th>
                        تاريخ انتهاء الاشتراك
                    </th>
                </thead>
                <tbody>
                    <tr v-for="u in users" :key="u._id">
                        <td>
                            {{ u.name }}
                        </td>
                        <td>
                            {{ u.phone }}
                        </td>
                        <td>
                            {{ u.password }}
                        </td>
                        <td dir="ltr">
                            {{ u.joindate.replace("T", ' ').split(".")[0] }}
                        </td>
                        <td>
                            {{ u.subscribe.demo ? 'نعم' : 'لا' }}
                        </td>
                        <td>
                            <input type="text" style="border: none; width: 100px; background: #fafafa" v-model="u.subscribe.exdate" @change="changeExdate(u._id, u.subscribe.exdate)">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem("user")),
            users: []
        }
    },
    created(){
        var g = this;
      $.post(api + "/admin/users", {
        jwt: this.user.jwt,
      })
        .then(function (r) {
          r = r;
          g.users = r.response;
          g.loading = false;
        })
        .catch(function (e) {
          g.allowed = false;
          alert("تعذر الإتصال بالخادم..");
        });
    },
    methods: {
        changeExdate(id, exdate){
            if(confirm("متاكد من تغيير تاريخ الانتهاء؟")){
                var g = this;
                $.post(api + "/admin/exdate", {
                    jwt: this.user.jwt,
                    exdate: exdate,
                    id: id
                })
                    .then(function (r) {
                   alert("تم التغيير بنجاح!")
                    })
                    .catch(function (e) {
                    g.allowed = false;
                    alert("تعذر الإتصال بالخادم..");
                    });
            }
        }
    }
}
</script>

<style>

</style>